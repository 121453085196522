<template>
  <div id="greetings">
    <vx-card slot="no-body" class="text-center greet-user">
      <div style="min-height: 195px">
        <img src="@/assets/images/elements/decore-left.png" class="decore-left" alt="Decore Left" width="200" >
        <img src="@/assets/images/elements/decore-right.png" class="decore-right" alt="Decore Right" width="175">
        <feather-icon :icon="greetingIcon" class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow" svgClasses="h-8 w-8"></feather-icon>
        <h3 class="mb-6">Selamat {{ greetingTime }}, {{ userName }}!</h3>
        <p v-if="totalPendingApproval > 0" class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-sm">Terdapat <strong>{{ totalPendingApproval }}</strong> approval yang memerlukan persetujuan Anda. Cek pada menu Approvals.</p>
        <template v-if="totalPendingApproval < 1">
          <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-sm">Selamat beraktifitas kembali di hari {{ dayName }}.</p>
          <div class="flex items-center justify-center space-x-1">
            <feather-icon icon="ClockIcon" svgClasses="h-4 w-4"></feather-icon>
            <p class="font-medium">{{ time }}</p>
          </div>
        </template>
      </div>
    </vx-card>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'Greetings',
  components: {},
  created () {
    this.interval = setInterval(() => {
      this.time = moment().format('hh:mm:ss A')
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  computed: {
    totalPendingApproval () {
      const badges = this.$store.state.general.navbarBadge.badges
      const approvalBadge = _.find(badges, badge => badge.menu_slug === 'approvals')
      return approvalBadge ? parseInt(approvalBadge.badge_text) : 0
    },
    greetingTime () {
      const hour = moment().hour()
      if (hour > 16) {
        return 'malam'
      } else if (hour > 14) {
        return 'sore'
      } else if (hour > 11) {
        return 'siang'
      }
      return 'pagi'
    },
    greetingIcon () {
      switch (this.greetingTime) {
        case 'malam':
          return 'MoonIcon'
        case 'sore':
          return 'SunsetIcon'
        case 'siang':
          return 'SunIcon'
        case 'pagi':
          return 'SunIcon'
        default:
          return 'SunIcon'
      }
    },
    userName () {
      const isStaf = !!this.$store.state.auth.user.staf
      const name = isStaf ? this.$store.state.auth.user.staf.nama : this.$store.state.auth.user.rekanan.nama
      const nameLength = name.length
      if (nameLength <= 14) {
        return name
      } else {
        return name.split(' ')[0]
      }
    },
    dayName () {
      return moment().locale('id').format('dddd')
    }
  },
  data () {
    return {
      isLoading: false,
      interval: null,
      time: moment().format('hh:mm:ss A')
    }
  }
}
</script>

<style lang="scss" scoped>
/*! rtl:begin:ignore */
#greetings {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
